@font-face {
    font-family: 'Bulgatti';
    src: url('BulgattiRegular.woff2') format('woff2'),
        url('BulgattiRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

h1 {
    font-family: Bulgatti;
    color:purple;
}